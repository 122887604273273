.savings-page {
  overflow: auto;
  cursor: default;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.savings-synopsis {
  padding: 1% 4% 2% 4%;
}

.savings-synopsis > div {
  white-space: pre-wrap;
  font-size: 1vw;
}

.chart-cont {
  margin: 3% 0% 2% 0%;
  border: solid 2px black;
  max-height: 90vh;
  width: 45vw;
}

@media (max-width: 70em) {
  .savings-synopsis {
    padding: 1% 7% 2% 7%;
  }

  .savings-synopsis > div {
    font-size: 110%;
  }

  .chart-cont {
    margin-bottom: 25%;
  }
}

@media (max-width: 65em) {
  .savings-synopsis {
    padding-bottom: 4%;
  }

  .savings-synopsis > div {
    font-size: 200%;
  }

  .chart-cont {
    margin: 3% 0% 20% 0%;
    width: 75%;
  }
}
