.navbar-cont {
  background-color: #101f33;
  height: 100vh;
}

.logo-cont {
  background-color: #ffffff;
}

.logo-cont > a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10% 0 10% 0;
}

.logo {
  width: 90%;
}

.navbar-main-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 7px;
  min-height: 84vh;
}

.navbar-route-row {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 12rem;
  border-bottom: solid 2px rgba(14, 109, 169, 0.397);
}

.navbar-route-row svg {
  margin-left: 10px;
}

.navbar-route-row > a {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.navbar-route-row:hover {
  background-color: rgba(14, 109, 169, 0.397);
}

.selected {
  background-color: rgb(4, 154, 204);
}

.not-selected {
  background-color: #101f33;
}

.services-row-comp-outside {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: default;
}

.services-row-comp-main-row {
  display: flex;
  align-items: center;
}

.navbar-text {
  text-shadow: 1.5px 1.5px black;
  color: white;
  font-weight: bold;
  font-size: 0.9vw;
  margin: 10px 0 10px 2rem;
  width: 100%;
}

.dropdown-cont-comp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  min-height: 5.7rem;
  background-color: #101f33;
}

.dropdown-cont-comp > div {
  width: 100%;
  padding: 2px 0 2px 0;
}

.services-dropdown-row {
  display: flex;
  text-decoration: none;
  align-items: center;
  font-size: 13px;
  padding-left: 5rem;
  width: 100%;
  min-height: 2rem;
}

.services-dropdown-row:hover {
  font-size: 14px;
  background-color: rgba(14, 109, 169, 0.397);
}

.selected:hover {
  background-color: rgb(4, 154, 204);
}

.navbar-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  border-top: solid 1px rgb(4, 154, 204);
  color: rgb(215, 209, 209);
  padding-top: 10px;
}

.navbar-footer > div {
  display: flex;
}

.key-footer-navbar {
  font-size: 12px;
  font-weight: bold;
  margin-right: 3px;
}

.value-footer-navbar {
  font-size: 12px;
  margin-right: 3px;
}

.copyright-navbar {
  font-weight: bold;
  font-size: 10px;
  padding-top: 1rem;
}

.white-text {
  margin: 0;
  text-align: center;
  text-shadow: 1.5px 1.5px black;
  color: white;
  text-decoration: none;
}

@media (max-width: 70em) {
  .navbar-cont {
    display: flex;
    height: 11vh;
    width: 100vw;
  }

  .logo-cont {
    height: 11vh;
    width: 100vw;
    display: flex;
    justify-content: center;
  }

  .logo-cont > a {
    height: 11vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 40%;
  }

  .logo {
    width: 80%;
    height: 9vh;
  }

  .menu-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    padding: 2rem;
    position: fixed;
    top: 0;
    right: 0;
    user-select: none;
    height: 11vh;
  }

  .dropdown-menu {
    top: 9vh;
    width: 40%;
    position: fixed;
    opacity: 1;
    cursor: pointer;
  }

  .dropdown-menu.active {
    right: 0;
    opacity: 1;
    transition: top 0.2s ease-out;
    list-style: none;
    padding: 0;
    background-color: #101f33;
  }

  .dropdown-route-row {
    text-align: center;
    padding: 1rem;
    width: 100%;
    height: 4.5rem;
    display: table;
    font-size: 150%;
    text-decoration: none;
    color: white;
    border-bottom: solid 2px rgb(210, 204, 204);
  }

  .dropdown-route-row:hover {
    background-color: rgb(4, 154, 204);
    border-radius: 0;
  }

  .dropdown-row-services-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .dropdown-row-services-mobile > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-row-services-mobile > div {
    padding-left: 35px;
  }

  .dropdown-row-services-mobile > svg {
    padding-left: 3px;
  }

  .dropdown-cont-our-services {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 120%;
    background-color: #172e4d;
    border-bottom: solid 2px rgb(210, 204, 204);
  }

  .dropdown-cont-our-services > div {
    width: 80%;
    text-align: center;
    padding: 3% 0 3% 0;
  }

  .dropdown-border {
    border-bottom: solid 2px rgb(210, 204, 204);
  }
}

@media (max-width: 65em) {
  .logo-cont {
    height: 11vh;
    width: 100vw;
  }

  .logo-cont > a {
    width: 50%;
  }

  .logo {
    width: 100%;
  }

  .dropdown-menu {
    top: 10.1vh;
    width: 50%;
  }

  .dropdown-route-row {
    height: 4.5rem;
    font-size: 220%;
  }

  .dropdown-cont-our-services {
    font-size: 200%;
  }
}
