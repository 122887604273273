.our-services-page {
  overflow: auto;
  cursor: default;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.service-header {
  display: flex;
  justify-content: center;
  padding-top: 2%;
  font-size: 3rem;
  font-weight: bold;
  color: rgb(11, 131, 178);
  text-shadow: 1.5px 1.5px rgb(15, 76, 91);
}

.service-synopsis {
  display: flex;
  justify-content: center;
  margin: 1% 1% 1% 1%;
  font-size: 18px;
}

.service-synopsis > div {
  padding: 0 10% 3% 10%;
  white-space: pre-wrap;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}

.youtube-video-header {
  margin-top: 0;
  text-align: center;
}

.video-responsive {
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 0%;
  position: relative;
  height: 35vh;
  width: 80vw;
  z-index: 1;
}

.video-responsive iframe {
  height: 85%;
  width: 40%;
  position: absolute;
  border: solid 1px rgb(200, 197, 197);
  z-index: 1;
}

.service-newsletter-cont {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.service-newsletter-cont > h2 {
  color: rgb(11, 131, 178);
  text-shadow: 1px 1px rgb(15, 76, 91);
}

.service-newsletter-cont > div {
  --box-border--border: linear-gradient(
    105deg,
    #101f33 0%,
    rgb(4, 154, 204) 25%,
    #101f33 50%,
    rgb(4, 154, 204) 75%,
    #101f33 100%
  );

  --box--border__top-left: url("data:image/svg+xml,<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='1' y='1' width='18' height='18' rx='4' ry='4' stroke='%23000' stroke-width='20' /></svg>");
  --box--border__top: url("data:image/svg+xml,<svg preserveAspectRatio='none' width='100' height='10' viewBox='0 0 100 10' fill='none' xmlns='http://www.w3.org/2000/svg'><line x1='-1' y1='1' x2='101' y2='1' stroke='%23000' stroke-width='20'/></svg>");
  --box--border__top-right: url("data:image/svg+xml,<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='-9' y='1' width='18' height='18' rx='4' ry='4' stroke='black' stroke-width='20' /></svg>");
  --box--border__left: url("data:image/svg+xml,<svg preserveAspectRatio='none' width='10' height='100' viewBox='0 0 10 100' fill='none' xmlns='http://www.w3.org/2000/svg'><line x1='1' y1='-1' x2='1' y2='101' stroke='%23000' stroke-width='20'/></svg>");
  --box--border__right: url("data:image/svg+xml,<svg preserveAspectRatio='none' width='10' height='100' viewBox='0 0 10 100' fill='none' xmlns='http://www.w3.org/2000/svg'><line x1='9' y1='-1' x2='9' y2='101' stroke='%23000' stroke-width='20'/></svg>");
  --box--border__bottom-left: url("data:image/svg+xml,<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='1' y='-9' width='18' height='18' rx='4' ry='4' stroke='%23000' stroke-width='20' /></svg>");
  --box--border__bottom: url("data:image/svg+xml,<svg preserveAspectRatio='none' width='100' height='10' viewBox='0 0 100 10' fill='none' xmlns='http://www.w3.org/2000/svg'><line x1='-1' y1='9' x2='101' y2='9' stroke='%23000' stroke-width='20'/></svg>");
  --box--border__bottom-right: url("data:image/svg+xml,<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='-9' y='-9' width='18' height='18' rx='4' ry='4' stroke='black' stroke-width='20' /></svg>");

  padding: 1rem;
  position: relative;
}

.service-newsletter-cont > div::before {
  content: "";
  position: absolute;
  border-radius: 0.3rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--box-border--border);
  mask: var(--box--border__top-left) 0 0 / 0.625rem 0.625rem,
    var(--box--border__top) 0.625rem 0 / calc(100% - 1.25rem) 0.625rem,
    var(--box--border__top-right) 100% 0 / 0.625rem 0.625rem,
    var(--box--border__left) 0 0.625rem / 0.625rem calc(100% - 1.25rem),
    var(--box--border__right) 100% 0.625rem / 0.625rem calc(100% - 1.25rem),
    var(--box--border__bottom-left) 0 100% / 0.625rem 0.625rem,
    var(--box--border__bottom) 0.625rem 100% / calc(100% - 1.25rem) 0.625rem,
    var(--box--border__bottom-right) 100% 100% / 0.625rem 0.625rem;
  mask-repeat: no-repeat;
}

.pagination-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1% 0% 2% 0%;
}

.button-cont {
  display: flex;
  justify-content: center;
  width: 10%;
}

.button-cont > div {
  cursor: pointer;
  margin-top: 3%;
}

.prev {
  margin-right: 4%;
}

.next {
  margin-left: 4%;
}

.single-quote-service-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 17%;
  margin-bottom: 2%;
}

.quote-service-page {
  width: 50vw;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 14px;
  color: rgb(78, 73, 73);
  margin: 0rem 0.5rem 0rem 0.5rem;
}

.quotee-service-page {
  color: rgb(36, 35, 35);
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
}

@media (max-width: 70em) {
  .single-quote-service-page {
    padding-left: 15%;
    margin-bottom: 12%;
  }

  .quote-service-page {
    width: 70vw;
  }

  .video-responsive {
    width: 110vw;
  }
}

@media (max-width: 65em) {
  .service-header {
    padding-top: 22%;
    font-size: 400%;
  }

  .service-synopsis {
    margin-top: 3%;
    font-size: 200%;
  }

  .service-newsletter-cont {
    font-size: 200%;
  }

  .pagination-cont {
    margin-bottom: 5%; /*was brand new */
  }

  .single-quote-service-page {
    padding-bottom: 5%;
  }

  .quote-service-page {
    font-size: 140%;
  }

  .quotee-service-page {
    font-size: 120%;
  }

  .video-responsive {
    width: 100%;
  }

  .video-responsive > iframe {
    width: 80vw;
  }

  .youtube-video-header {
    margin-top: 2vh;
    font-size: 210%;
  }
}
