.main-app {
  display: flex;
  min-height: 100vh;
  background-color: rgb(224, 218, 218);
}

.nav-cont {
  min-width: 17vw;
  max-width: 17vw;
}

.routes-cont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  cursor: default;
  background-color: rgba(255, 255, 255, 0.803);
}

@media (max-width: 70em) {
  .main-app {
    display: flex;
    flex-direction: column;
  }

  .nav-cont {
    z-index: 2;
    position: fixed;
    width: 100vw;
    height: 11vh;
  }

  .routes-cont {
    z-index: 1;
    position: fixed;
    margin-top: 7%;
  }
}

@media (max-width: 65em) {
  .routes-cont {
    margin-top: -0.5%;
  }
}

@media (max-height: 45em) {
  .main-app {
    display: flex;
    flex-direction: column;
  }

  .nav-cont {
    z-index: 2;
    position: fixed;
    width: 100vw;
    height: 11vh;
  }

  .routes-cont {
    z-index: 1;
    position: fixed;
  }
}
