.about-us-page {
  cursor: default;
  overflow: auto;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.single-ee-cont {
  display: flex;
  min-width: 75%;
  max-width: 75%;
  min-height: 57%;
  max-height: 57%;
  margin: 2% 0% 2% 0%;
  border: solid 5px black;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px #101f33, 0 2px 4px #101f33, 0 4px 8px #101f33,
    0 -1px 2px #101f33, 0 -2px 4px #101f33, 0 -4px 8px #101f33;
}

.general-info-cont {
  display: flex;
  flex-direction: column;
  flex: 0.3;
  justify-content: center;
  align-items: center;
  padding: 2%;
  background: linear-gradient(
    to bottom right,
    black 0%,
    rgba(0, 0, 0, 0.9) 10%,
    rgba(0, 0, 0, 0.8) 20%,
    rgba(0, 0, 0, 0.7) 30%,
    rgba(0, 0, 0, 0.6) 40%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.6) 60%,
    rgba(0, 0, 0, 0.7) 70%,
    rgba(0, 0, 0, 0.8) 80%,
    rgba(0, 0, 0, 0.9) 90%,
    black 100%
  );
  min-width: 23rem;
}

.photo {
  grid-row: span 2;
  grid-column: span 2;
  width: 15rem;
  height: 15rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 60rem;
}

.general-info-cont > h1 {
  font-size: 26px;
}

.general-info-cont > h2 {
  margin: 0 0 4% 0;
  font-size: 22px;
}

.general-info-cont > h4 {
  margin: 0;
}

.about-me-cont {
  display: flex;
  flex: 0.7;
  flex-direction: column;
  padding: 0% 2% 0% 2%;
  border-top-right-radius: 0.5rem;
  background: linear-gradient(
    to bottom left,
    lightgrey 0%,
    rgba(211, 211, 211, 0.9) 10%,
    rgba(211, 211, 211, 0.8) 20%,
    rgba(211, 211, 211, 0.7) 30%,
    rgba(211, 211, 211, 0.6) 40%,
    rgba(211, 211, 211, 0.5) 50%,
    rgba(211, 211, 211, 0.6) 60%,
    rgba(211, 211, 211, 0.7) 70%,
    rgba(211, 211, 211, 0.8) 80%,
    rgba(211, 211, 211, 0.9) 90%,
    lightgrey 100%
  );
}

.about-me-cont > h1 {
  margin-bottom: 0;
  text-shadow: 1.5px 1.5px white;
}

.about-me-cont > div {
  overflow: auto;
  font-size: 1.2vw;
  border-top: solid 2px rgb(174, 166, 166);
  padding: 1% 0% 0% 0%;
  white-space: pre-wrap;
}

@media (max-width: 70em) {
  .single-ee-cont {
    min-width: 90%;
    max-width: 90%;
  }

  .seec-last {
    margin-bottom: 12%;
  }

  .about-me-cont > div {
    font-size: 120%;
  }
}

@media (max-width: 65em) {
  .about-us-page {
    justify-content: space-center;
  }

  .about-us-mobile-header-mobile {
    display: flex;
    justify-content: center;
    padding: 25% 0 2% 0; /*top was 22% bottom was 5%*/
    margin: 0;
    font-size: 220%;
    font-weight: bold;
    color: rgb(11, 131, 178);
    text-align: center;
    max-width: 70%;
    text-decoration: none;
  }

  .about-us-mobile-header-mobile-single-ee-page {
    display: flex;
    justify-content: center;
    padding: 25% 0 5% 0; /*top was 22% */
    margin: 0;
    font-size: 220%;
    font-weight: bold;
    color: rgb(11, 131, 178);
    text-align: center;
    max-width: 70%;
    text-decoration: none;
  }

  .single-ee-page-mobile {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
  }

  .single-ee-cont {
    min-height: 21%;
    max-height: 21%;
    min-width: 80%;
    max-width: 80%;
    text-decoration: none;
  }

  .single-ee-cont-mobile {
    display: flex;
    flex-direction: column;
    min-width: 85%;
    max-width: 85%;
    min-height: 68%; /*top was 70% */
    max-height: 68%;
    margin: 2% 0% 2% 0%;
    border: solid 5px black;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px #101f33, 0 2px 4px #101f33, 0 4px 8px #101f33,
      0 -1px 2px #101f33, 0 -2px 4px #101f33, 0 -4px 8px #101f33;
  }

  .general-info-cont-single-mobile {
    display: flex;
    flex-direction: column;
    flex: 0.3;
    justify-content: center;
    align-items: center;
    padding: 5% 0 2% 0; /*top was 10% */
    background: linear-gradient(
      to bottom right,
      black 0%,
      rgba(0, 0, 0, 0.9) 10%,
      rgba(0, 0, 0, 0.8) 20%,
      rgba(0, 0, 0, 0.7) 30%,
      rgba(0, 0, 0, 0.6) 40%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.6) 60%,
      rgba(0, 0, 0, 0.7) 70%,
      rgba(0, 0, 0, 0.8) 80%,
      rgba(0, 0, 0, 0.9) 90%,
      black 100%
    );
    min-width: 23rem;
  }

  .general-info-cont-single-mobile > h1 {
    margin: 0 0 2% 0;
    font-size: 300%;
  }

  .general-info-cont-single-mobile > h2 {
    margin: 0 0 2% 0;
    font-size: 180%;
  }

  .general-info-cont-single-mobile > h4 {
    margin: 0 0 1% 0;
    font-size: 140%;
  }

  .general-info-cont {
    flex: 1;
    flex-direction: row;
    justify-content: center;
  }

  .photo-mobile {
    width: 35rem;
    height: 35rem;
    border-radius: 60rem;
  }

  .photo {
    width: 18rem;
    height: 18rem;
    border-radius: 60rem;
  }

  .general-info-cont > div {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 5%;
  }

  .general-info-cont h1 {
    margin: 0 0 4% 0;
    font-size: 300%;
  }

  .general-info-cont h2 {
    margin: 0 0 4% 0;
    font-size: 160%;
  }

  .general-info-cont h4 {
    margin: 0 0 4% 0;
    font-size: 140%;
  }

  .about-me-cont-mobile {
    justify-content: center;
    overflow: auto;
    font-size: 160%;
    padding: 1% 0% 0% 0%;
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    white-space: pre-wrap;
    display: flex;
    flex: 0.7;
    flex-direction: column;
    padding: 0% 2% 0% 2%;
    background: linear-gradient(
      to bottom left,
      lightgrey 0%,
      rgba(211, 211, 211, 0.9) 10%,
      rgba(211, 211, 211, 0.8) 20%,
      rgba(211, 211, 211, 0.7) 30%,
      rgba(211, 211, 211, 0.6) 40%,
      rgba(211, 211, 211, 0.5) 50%,
      rgba(211, 211, 211, 0.6) 60%,
      rgba(211, 211, 211, 0.7) 70%,
      rgba(211, 211, 211, 0.8) 80%,
      rgba(211, 211, 211, 0.9) 90%,
      lightgrey 100%
    );
  }
}
