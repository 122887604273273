.membs-page {
  overflow: auto;
  cursor: default;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.membs-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  margin: 2% 0% 2% 0%;
}

.mems-select {
  border-radius: 0.5rem;
  margin-right: 4%;
}

.membs-dropdown > button {
  text-align: center;
  cursor: pointer;
  background-color: #101f33;
  color: rgb(4, 154, 204);
  font-weight: bold;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: 8rem;
  margin-left: 4%;
}

.membs-dropdown > div {
  margin: 0;
}

.membs-cont-full {
  width: 80.7vw;
}

.asterisk-cont {
  margin: 1%;
  font-weight: bold;
}

.membs-cont-outside {
  border-radius: 0.5rem;
  height: 80.7vh;
  width: 80.7vw;
  border: solid 8px #101f33;
  box-shadow: 0 1px 2px #101f33, 0 2px 4px #101f33, 0 4px 8px #101f33,
    0 -1px 2px #101f33, 0 -2px 4px #101f33, 0 -4px 8px #101f33;
}

.membs-text-cont {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1% 0 1% 2%;
  min-height: 79vh;
  max-height: 79vh;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  overflow: auto;
  box-shadow: 0 1px 2px #101f33, 0 2px 4px #101f33, 0 4px 8px #101f33,
    0 -1px 2px #101f33, 0 -2px 4px #101f33, 0 -4px 8px #101f33;
  background: linear-gradient(
    to bottom right,
    rgba(14, 109, 169, 0.397) 0%,
    rgba(14, 109, 169, 0.397) 10%,
    rgba(156, 191, 213, 0.397) 20%,
    rgba(14, 109, 169, 0.397) 30%,
    rgba(14, 109, 169, 0.397) 40%,
    rgba(14, 109, 169, 0.397) 50%,
    rgba(14, 109, 169, 0.397) 60%,
    rgba(156, 191, 213, 0.397) 70%,
    rgba(14, 109, 169, 0.397) 80%,
    rgba(14, 109, 169, 0.397) 100%
  );
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom right, #485563 0%, #29323c 100%);
}

::-webkit-scrollbar {
  width: 0.3rem;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
}

.membs-column {
  min-width: 29%;
}

.membs-column > div {
  font-size: 1.2vw;
}

.last-item-in-column {
  padding-bottom: 10%;
}

@media (max-width: 70em) {
  .membs-cont-outside {
    height: 62vh;
  }

  .membs-text-cont {
    min-height: 60vh;
    max-height: 60vh;
    border-bottom-left-radius: 0rem;
  }

  .membs-column > div {
    font-size: 100%;
  }
}

@media (max-width: 65em) {
  .membs-page {
    padding-top: 20%;
  }

  .membs-dropdown {
    flex-direction: column;
  }

  .membs-dropdown > button {
    height: 4rem;
    padding: 1%;
    min-width: 38%;
    margin: 8% 0 2% 0;
    font-size: 150%;
  }

  .membs-cont-full {
    padding-top: 1%;
  }

  .asterisk-cont {
    font-size: 200%;
  }

  .membs-cont-outside {
    height: 55vh;
  }

  .membs-text-cont {
    min-height: 54vh;
    max-height: 54vh;
    justify-content: space-around;
  }

  .membs-column > div {
    font-size: 145%;
  }
}
