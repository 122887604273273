.faqs-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: default;
  overflow: auto;
  height: 100vh;
}

.faq-full-cont {
  display: flex;
  flex-direction: column;
  padding-top: 3%;
  margin: 1rem;
}

.faq-single-cont {
  border-bottom: solid 2px rgb(190, 176, 176);
  min-width: 70vw;
  max-width: 70vw;
}

.faq-single-cont:hover > .faq-single-q-cont {
  background-color: rgba(238, 234, 234, 0.803);
}

.faq-single-q-cont {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.row-cont {
  display: flex;
  align-items: center;
}

.rc-q {
  min-width: 68vw;
  max-width: 68vw;
}

.q {
  margin: 0.4rem 0rem 0rem 0.5rem;
  padding: 0.5rem 0.5rem 0.5rem 0rem;
  font-size: 20px;
  font-weight: bold;
}

.a {
  margin: 0.4rem 0rem 0rem 0.5rem;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  font-size: 16px;
}

.a-l {
  margin: 0.4rem 0rem 0rem 0.5rem;
}

.link {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 70em) {
  .faq-full-cont {
    padding-bottom: 25%; /* brand new */
  }
}

@media (max-width: 65em) {
  .faq-full-cont {
    margin-top: 25%;
  }

  .faq-single-cont {
    min-width: 90vw;
    max-width: 90vw;
  }

  .rc-q {
    min-width: 85vw;
    max-width: 85vw;
  }

  .q {
    font-size: 180%;
  }

  .a {
    font-size: 170%;
  }
}
