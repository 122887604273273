.home-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  height: 100vh;
}

.skyline {
  max-height: 70%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
  width: 100%;
}

.statement {
  font-size: 30px;
  color: white;
  text-align: center;
  text-shadow: 1.5px 1.5px black;
  margin-top: -11%;
  max-width: 80%;
}

.synopsis-cont {
  max-width: 75%;
  text-align: center;
  font-family: sans-serif;
  color: #101f33;
  margin-top: 5%;
}

.synopsis1 {
  white-space: pre-wrap;
  font-size: 16px;
  margin: 1rem 0rem 1rem 0rem;
}

.link-box-outside {
  display: flex;
  width: 90%;
  justify-content: center;
}

.single-link-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 17rem;
  height: 13rem;
  background-color: #101f33;
  color: rgb(215, 209, 209);
  text-shadow: 0.5px 0.5px rgb(11, 131, 178);
  margin: 3rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px #101f33, 0 2px 4px #101f33, 0 4px 8px #101f33,
    0 -1px 2px #101f33, 0 -2px 4px #101f33, 0 -4px 8px #101f33;
}

.single-link-box:hover {
  background-color: rgb(9, 73, 112);
}

.youtube-video-header-home-page {
  color: #101f33;
}

.video-responsive-home-page {
  display: flex;
  justify-content: center;
  padding: 0%;
  position: relative;
  height: 35vh;
  width: 80vw;
}

.video-responsive-home-page iframe {
  height: 200%;
  width: 40%;
  border: solid 1px rgb(200, 197, 197);
}

/*https://www.w3.org/Style/Examples/007/slideshow.en.html*/
.slideshow {
  margin: 13vw 0% 2% 0%;
  position: relative;
  min-width: 60%;
  max-height: 20rem;
}

.slideshow > * {
  visibility: hidden;
  position: absolute;
  animation: 70s autoplay1 infinite; /*how many seconds the entire slideshow will play before it starts from the top again*/
}

.quote {
  width: 50vw;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 1vw;
  color: rgb(78, 73, 73);
  margin: 0rem 0.5rem 0rem 0.5rem;
}

.quotee {
  color: rgb(36, 35, 35);
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
}

@keyframes autoplay1 {
  0% {
    visibility: visible;
  }

  /*  visibility = 100 / number of slides */
  /*if there are three slides, then the answer below is 33.33%*/
  /*if there are 10 slides, then the answer below is 10%*/
  14% {
    visibility: hidden;
  }
}

.slideshow > *:nth-child(1) {
  animation-delay: 0s;
}

.slideshow > *:nth-child(2) {
  animation-delay: 10s;
}

.slideshow > *:nth-child(3) {
  animation-delay: 20s;
}

.slideshow > *:nth-child(4) {
  animation-delay: 30s;
}

.slideshow > *:nth-child(5) {
  animation-delay: 40s;
}

.slideshow > *:nth-child(6) {
  animation-delay: 50s;
}

.slideshow > *:nth-child(7) {
  animation-delay: 60s;
}

.footer-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15%;
}

.company-info-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 70vw;
  padding-top: 1%;
  border-top: solid 2px rgb(192, 183, 183);
}

.company-info-footer > div {
  display: flex;
}

.company-info-footer > div > div {
  margin-top: 5px;
}

.key-footer {
  font-size: 12px;
  font-weight: bold;
  margin-right: 3px;
}

.value-footer {
  font-size: 12px;
}

.copyright {
  font-weight: bold;
  font-size: 10px;
  padding: 1rem;
}

@media (max-width: 70em) {
  .video-responsive-home-page iframe {
    width: 70%; /*was 60% */
    height: 170%; /*was 200% */
  }

  .slideshow {
    margin: 27vw 0% 3vh 10vw; /*was 17vw for top */
    min-width: 90%;
  }

  .quote {
    width: 80vw;
    font-size: 80%;
  }

  .footer-cont {
    padding-bottom: 5%; /*was brand new */
  }

  .company-info-footer {
    min-width: 75vw;
    padding-bottom: 7%;
  }
}

@media (max-width: 65em) {
  .skyline {
    padding-top: 11vh;
  }

  .statement {
    max-width: 82%;
  }

  .synopsis-cont {
    max-width: 90%;
  }

  .synopsis1 {
    text-align: start;
    font-size: 180%;
  }

  .single-link-box {
    min-width: 28%;
    margin: 2rem;
  }

  .video-responsive-home-page iframe {
    width: 80%;
    height: 80%; /*was 120% */
  }

  .slideshow {
    margin: 5vw 0% 16vh 10vw; /*was 10vw on top */
    min-width: 90%;
  }

  .quote {
    width: 85vw;
    font-size: 150%;
  }

  .quotee {
    font-size: 100%;
  }

  .footer-cont {
    margin-bottom: 5%; /*was brand new */
  }

  .company-info-footer {
    min-width: 95vw;
    border-top: solid 4px rgb(192, 183, 183);
  }

  .footer-cont div {
    font-size: 110%;
  }
}
